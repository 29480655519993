import { inject } from '@angular/core';
import {
	Router,
	CanActivateFn,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '@emrm/core/auth/service';

export const AuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	const router: Router = inject(Router);
	const authService: AuthService = inject(AuthService);

	const redirectUrl = state.url !== '/' ? state.url : '';

	if (!authService.isLoggedIn(redirectUrl)) {
		router.navigate(['/auth']);

		return false;
	}

	return true;
};
